import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import "@fontsource/metropolis/500.css";
import "@fontsource/open-sans";
import "@fontsource/arimo";

const BiographyContent = () => {
  const [isContentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Core
      style={{
        opacity: isContentLoaded ? "1" : "0",
        transitionDuration: "1s",
        transitionTimingFunction: "ease-in",
      }}
    >
      <BioImg>
        <StaticImage
          src="../images/bioImage.jpg"
          alt="123"
          objectFit="contain"
          width={700}
        />

        <TextContainer>
          <BioText>
            <p>
              Miriam is a professional dancer and model based in the London UK.
            </p>
            <p>
              Professionally associated with art from the age of 9. Yet, after
              all these years She can't imagine doing anything else.
            </p>
            <p>
              Her work has been published in photography and art exhibitions in
              galleries across world.
            </p>
            <p>
              With her immense amount of experience and knowledge about the
              industry she's more than happy to share it with you.
            </p>
            <p>Let's create togheter!</p>
          </BioText>
        </TextContainer>
      </BioImg>

      <StyleLineS></StyleLineS>

      <BioDesc>
        <p style={{ fontSize: 32, color: "#410345" }}>Bio</p>
        <p>Miriam was born in Łódź, Poland.</p>
        <p>
          Professional title of a dancer after graduation from the Parnell
          Ballet School in Lodz, Poland.{" "}
        </p>
        <p>
          She worked at the Wroclaw Opera House in Poland. She took part in the
          classical ballet and opera repertoire, where she performed in: “Swan
          Lake”, “Don Quixote”, “The Nutcracker”, “Cinderella”, “Paradise Lost”
          - K.Penderecki, “Fiddler on the Roof”, “Carmen” and more.
        </p>
        <p>
          Dancing skills have allowed her to develop as an artist model where
          she has worked with a variety of artists all over the world.
        </p>
        <p>
          As a model, she has been published many times in international
          magazines such as “Capture” - Australia(Cover), “Vouge”- Italy,
          “Image”- China (Cover), “Digital Camera”- Poland, “Photo Kurier”, -
          Poland, “Make-Up Trendy”, “Eks-Magazyn”, “Essex Bride”- UK (Cover),
          “Airbase”-UK (Cover) Art Books: PLATINUM- Wacław Wantuch, SILVER-
          Yerbury, TRIBUTE- Branicki, “Dance Composition Basics”- Pamela
          A.Sofras (PL) and more.
        </p>
        <p>
          She has worked with photographers such as: Waclaw Wantuch (Pl),Marc
          Lagrange(NL),Thomas Holm (DK), Andre Brito (PT) and more.
        </p>
        <p>
          Selected exibitions and press: • PHOTOFEVER Paris - exibitions of
          Wacław Wantuch,”Platinum”. • Warsaw leica Gallery, Tengu Gallery, 2016
          W. Wantuch. • Invaluable auction lot 181, W.Wantuch. • Moscow
          International foto Awards. • House of Vans- Fashion exibitions, London
          (UK) • Falsterbo Photo Art Museums-Ballerina Triptyk, Sweden 2021 •
          Niş Art Gallery - Ballerina paintings Istanbul, Turkey. • Scupture
          exibitions: In Good Company, Nicole Farhi, London (UK), Mall Galleries
          and more.
        </p>
        <p>
          Other: Fallen Angeles: Arum Light-Milk Calendar 2016, Sports
          Calendar-D.Cuda 2015, Biomed Calendar 2015, BRAUTMAGAZIN- exclusive
          wedding magazine, Absolutely Weddings - UK, JustEat, Lucozade fit
          Water, UNITE- haircare, Edge of Ember, Butterfly twist.
        </p>
        <p>
          She also collaborated with: UAL: London College of Fashion as a
          model/dancer on costume design and performance, fashion photography
          and hair and make up section. Univesity of the Arts London - Art
          Model. The British Museum - Greek pantheon - Art model. National Film
          & Television School (NFTS) - Actress/model. Mall Galleries, Instictive
          Drawing - Model/dancer. The Courtauld Institute of Art - Art model.
          Mediatonic games - Art Dance/Model. Royal institute of oil painters
          (THE ROI) - Art model. Wimbledon Art Studio - art model. Hesketh
          Hubbard Art Society - art model. Olimpia Professional Beauty show 2015
          - Make up & body painting model. West Thames College - Make up, Hair &
          body painting model Atelier Art School LARA - Art model/dancer. London
          Fine Art Studio - Art model
        </p>
      </BioDesc>

      <BtnContainer>
        <NavLink to="/contact">
          <ContactBtn>GET IN TOUCH</ContactBtn>
        </NavLink>
      </BtnContainer>
    </Core>
  );
};

export default BiographyContent;

const Core = styled.div`
  color: black;
  margin-left: 18vw;
  background-color: white;
  padding: 10px;
  height: 100%;
  margin-right: 18vw;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const TextContainer = styled.div`
  background: rgba(81, 77, 69, 0.8);

  width: 540px;
  height: auto;
  right: 50px;
  bottom: -10%;

  position: absolute;
  padding: 60px;

  @media screen and (max-width: 1440px) {
    right: -50px;
    width: 440px;
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 768px) {
    width: 440px;
    right: 0px;
    padding: 40px;
  }

  @media screen and (max-width: 425px) {
    width: 300px;
    right: -10px;
  }
  @media screen and (max-width: 320px) {
    width: 250px;
    bottom: -25%;
  }
`;

const BioText = styled.div`
  color: black;

  letter-spacing: 1.5px;

  & p {
    font-family: Arimo;
    font-size: 22px;
    margin-bottom: 20px;
    color: white;
  }

  & p:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    & p {
      font-size: 19px;
    }
  }
  @media screen and (max-width: 768px) {
    & p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 425px) {
    & p {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 320px) {
    & p {
      font-size: 11px;
      margin-bottom: 10px;
    }
  }
`;

const BioImg = styled.div`
  display: flex;
  position: relative;
`;

const BioDesc = styled.div`
  font-size: 2.2rem;
  margin-top: 20px;
  padding: 10px;
  font-family: "Metropolis";

  & p {
    letter-spacing: 1.1px;
    font-size: 20px;
    font-family: "Metropolis";
    margin-bottom: 15px;
  }
`;

const BtnContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
`;

const ContactBtn = styled.button`
  background-color: white;
  color: #3e3e3e;
  width: 140px;
  height: 55px;
  cursor: pointer;

  font-family: "Jost";
  font-size: 16px;
  font-weight: bolder;
  border: 2px solid #3e3e3e;

  @media screen and (max-width: 1440px) {
    transform: translateX(0);
  }

  &:hover {
    background-color: #3e3e3e;
    color: white;
  }
`;

const StyleLineS = styled.div`
  margin-top: 150px;
  position: relative;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: black;
    margin: 0 10px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }
`;

const NavLink = styled(Link)``;
