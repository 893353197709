import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import BiographyContent from "../components/BiographyContent";

const BiographyPage = () => (
  <Layout descriptionText={"Biography"}>
    <Seo title="biography" />

    <BiographyContent />
  </Layout>
);

export default BiographyPage;
